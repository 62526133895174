/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubjectLookup } from "@vaes-dashboard-2/graphql/genql"
import {
    VaesUserRoles,
    isClientOrClientAdmin,
} from "@vaes-dashboard-2/graphql/userRoles"
import { useLocation } from "react-router-dom"
import streamSaver from "streamsaver"
import { ZIP } from "../ZipStream"
import { notification } from "antd"
import * as Sentry from "@sentry/react"
import { AnyObject } from "antd/es/_util/type"
import i18next from "i18next"

/**
 * Extract user initials from name
 */
export const getUserInitials = (username: string) => {
    if (!username) return ""

    const userInitials = username
        .trim()
        .charAt(0)
        .toUpperCase()
        .concat(
            username.trim().lastIndexOf(" ") != -1
                ? username
                      .trim()
                      .charAt(username.trim().lastIndexOf(" ") + 1)
                      .toUpperCase()
                : ""
        )
    return userInitials
}

/**
 * Validate email
 */
export function isValidEmail(email: string) {
    // Email validation regular expression
    const emailRegex = /\S+@\S+\.\S+/

    // Test the email against the regex and return the result
    return emailRegex.test(email)
}
/** regex function to get the total saving pattern  */
export function isTotalSavings(subject: string) {
    const re = /(total)(.*)(saving)(s*)$/gi
    return re.test(subject)
}

/**
 * Format big number with commas, it accepts string, ReactNode or number, valid numbers will be formatted, invalid will be returned as is
 */
export const formatBigNumber = (value?: number | string | null) => {
    if (value == null) return ""

    if (typeof value !== "number" && !!Number.isNaN(Number(value))) {
        return value
    }

    const options = {
        style: "decimal",
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
    }

    const number = Number(value)
    return number.toLocaleString("en-US", options)
}

/**
 * Sort report subjects by:
 * Always have the costs on the bottom of the report
 * Always dedicate the last row to total cost savings or total material savings (whichever is highlighted in blue on that report)
 */
export const sortReportSubjects = (subjects: SubjectLookup[]) => {
    return [...subjects].sort((a, b) => {
        const name1 = a.name?.toLowerCase()
        const name2 = b.name?.toLowerCase()

        if (!name1 || !name2) return 0

        if (name1.includes("cost") && !name2.includes("cost")) {
            return 1 // Move a with "Cost" to the end
        }
        if (!name1.includes("cost") && name2.includes("cost")) {
            return -1 // Move b with "Cost" to the end
        }
        if (
            (name1.includes("savings") || name1.includes("savings")) &&
            !name2.includes("savings")
        ) {
            return name1 === "total cost savings" ? 1 : -1 // Move "Total Cost Savings" to the very end
        }
        if (
            !name1.includes("savings") &&
            !name1.includes("savings") &&
            (name2.includes("savings") || name2.includes("savings"))
        ) {
            return name2 === "total cost savings" ? -1 : 1 // Move "Total Cost Savings" to the very end
        }
        return 0 // Maintain the original order for other elements
    })
}

/**
 *  Simple utility to download a link using javascript
 */
export function downloadFile(url: string) {
    const link = document.createElement("a")
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

/**
 * Get actual file name or file path (including folder(s) names)
 */
export const getFileNameFromS3Key = (s3Key: string, fullPath = false) => {
    if (!s3Key) return null

    // why 4, please see the generateS3key function in utils.ts
    if (fullPath) return s3Key.split("/").slice(4).join("/")

    return s3Key.split("/").pop()
}

/**
 * Get role label (what is displayed in the UI)
 */
export const getRoleLabel = (
    roleName: VaesUserRoles,
    currentUserRoleName?: VaesUserRoles
) => {
    const isClientUser = isClientOrClientAdmin(currentUserRoleName || roleName)

    if (roleName === "ClientOwner" || roleName === "ClientAdmin")
        return isClientUser ? "Admin" : "Client Admin"
    if (roleName === "VaesEngineer") return "Engineer"
    if (roleName === "VaesEngineeringManager") return "Vaes Engineering Manager"
    if (roleName === "Client") return isClientUser ? "User" : "Client"
}

/**
 * Get page name from location without url params
 */
export const usePageName = () => {
    const location = useLocation()
    const pageName = location.pathname
    const PageName = pageName
        .slice(1)
        .split("/")
        .filter((part) => !Number(part))
    return PageName.join("-") ? PageName.join("-") : "Home Page"
}

// TODO: use env variables
export const getPageUrl = () => {
    const url = window.document.location.href
    if (url.includes("https://dashboard.vaes.ai")) {
        return "Production"
    } else if (url.includes("https://dashboard.vaes.dev")) {
        return "Development"
    } else {
        return "Local"
    }
}

/**
 * Compress (on the fly) multiple links into a zip file and stream it (download) it.
 * This function uses jimmywarting/StreamSaver.js to create a write stream and zip.js to compress the files
 * There is a native browser API for filesystem but it is not supported by all browsers
 */
export const compressAndDownload = async (
    urls: string[],
    filenames: string[],
    zipFileName: string
) => {
    // create a generator function to get the files in the format that zip.js expects
    async function* fileGenerator() {
        for (let i = 0; i < urls.length; i++) {
            const res = await fetch(urls[i])
            const stream = () => res.body
            yield { stream, name: filenames[i] }
        }
    }

    // create write stream
    const fileStream = streamSaver.createWriteStream(zipFileName)
    const fileIterator = fileGenerator()

    //TODO:
    //     Handle windows gets confused when file & folders starts with /
    //     This zip library has a limit of 4GB, we need to find a better library
    const readableZipStream = new (ZIP as any)({
        // Gets executed everytime zip.js asks for more data
        async pull(ctrl: any) {
            const file = await fileIterator.next()
            if (file.done) {
                // if (done adding all files)
                ctrl.close()
                return
            }
            // process the file
            ctrl.enqueue(file.value)
        },
    })

    return readableZipStream.pipeTo(fileStream)
}

/**
 * Check for any duplicate file names and add a number to the end of the file name in format of {fileName}({count}).{fileExtension}
 * @param paths array of file paths
 * @returns array of file paths with unique names
 */
export const ensureUniqueFilesPath = (paths: string[]) => {
    const map = new Map<string, number>()

    return paths.map((path) => {
        const count = map.get(path)
        if (typeof count === "number") {
            map.set(path, count + 1)
            return `${path} (${count + 1})`
        } else {
            map.set(path, 0)
            return path
        }
    })
}

/**
 * Check if two objects are equal
 */
export function deepEqual(obj1: AnyObject, obj2: AnyObject): boolean {
    const ok = Object.keys,
        tx = typeof obj1,
        ty = typeof obj2
    return obj1 && obj2 && tx === "object" && tx === ty
        ? ok(obj1).length === ok(obj2).length &&
              ok(obj1).every((key) => deepEqual(obj1[key], obj2[key]))
        : obj1 === obj2
}

/**
 * Recursively removes properties with null values from an object.
 * @param originalObj - The original object to process.
 * @returns A new object with null values removed.
 */
export function removeNullValues<T>(originalObj: T): Partial<T> {
    function deepCopy(obj: any): any {
        if (obj == null || typeof obj !== "object") {
            // Return non-object values directly
            return {}
        }

        if (Array.isArray(obj)) {
            // If the property value is an array, recursively create a deep copy.
            return obj.map((item) => deepCopy(item))
        }

        // If the property value is an object, recursively create a deep copy.
        const result: any = {}
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key]
                if (value !== null) {
                    result[key] = deepCopy(value)
                }
            }
        }

        return result
    }

    return deepCopy(originalObj) as Partial<T>
}

export const getRoleName = (
    companyId: number,
    value: string,
    hasArical?: boolean | false
) => {
    if (companyId == 0) {
        if (value != "VaesEngineeringManager") {
            return hasArical
                ? `an ${i18next.t("engineer", { ns: "common" })}`
                : i18next.t("engineer", { ns: "common" })
        } else {
            return hasArical
                ? `a ${i18next.t("manager", { ns: "common" })}`
                : i18next.t("manager", { ns: "common" })
        }
    } else {
        if (value == "Client") {
            return hasArical
                ? `a ${i18next.t("user", { ns: "common" })}`
                : i18next.t("user", { ns: "common" })
        } else {
            return hasArical
                ? `an ${i18next.t("admin", { ns: "common" })}`
                : i18next.t("admin", { ns: "common" })
        }
    }
}

/**
 * Generic error handler for dropzone and files selector errors, it shows a notification with the error message
 * it has a custom message for DOMException error code 8 (file path too long)
 * @param err
 */
export const dropzoneErrorHandler = (err: Error) => {
    let message
    if (err instanceof DOMException) {
        if (err.code === 8 || err.name === "NOT_FOUND_ERR") {
            message =
                "Files path is too long, please move the files to a shorter path"
        }
    }

    notification.error({
        message: "Error",
        description: message || err.message,
        key: "File upload error",
    })

    // log error to sentry
    Sentry.captureException(err)
}

export const sleepFor = (delay: number) =>
    new Promise((resolve) => setTimeout(resolve, delay))
