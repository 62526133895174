export default {
    "scalars": [
        1,
        2,
        6,
        11
    ],
    "types": {
        "Company": {
            "address": [
                1
            ],
            "contractor_type_key": [
                12
            ],
            "country": [
                1
            ],
            "createdAt": [
                1
            ],
            "currency_key": [
                12
            ],
            "email": [
                1
            ],
            "id": [
                2
            ],
            "industry_key": [
                12
            ],
            "logo": [
                1
            ],
            "name": [
                1
            ],
            "other_type": [
                1
            ],
            "phone": [
                1
            ],
            "projects": [
                16
            ],
            "size_key": [
                12
            ],
            "updatedAt": [
                1
            ],
            "users": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "String": {},
        "Int": {},
        "CompanyInput": {
            "address": [
                1
            ],
            "contractor_type_key": [
                1
            ],
            "country": [
                1
            ],
            "currency_key": [
                1
            ],
            "email": [
                1
            ],
            "industry_key": [
                1
            ],
            "logo_key": [
                1
            ],
            "name": [
                1
            ],
            "other_type": [
                1
            ],
            "phone": [
                1
            ],
            "size_key": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompletePart": {
            "ETag": [
                1
            ],
            "PartNumber": [
                2
            ],
            "__typename": [
                1
            ]
        },
        "DetailInput": {
            "subjectId": [
                2
            ],
            "value": [
                6
            ],
            "value2": [
                6
            ],
            "__typename": [
                1
            ]
        },
        "Float": {},
        "FilesSession": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "id": [
                2
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "Iteration": {
            "advantages": [
                1
            ],
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "description": [
                1
            ],
            "details": [
                9
            ],
            "gifImgUrl": [
                27
            ],
            "id": [
                2
            ],
            "imageUrl": [
                27
            ],
            "name": [
                1
            ],
            "order": [
                2
            ],
            "originalDesignImageUrl": [
                27
            ],
            "page": [
                25
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "IterationDetails": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "id": [
                2
            ],
            "iterationId": [
                2
            ],
            "subject": [
                28
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "value": [
                6
            ],
            "value2": [
                6
            ],
            "__typename": [
                1
            ]
        },
        "IterationInput": {
            "advantages": [
                1
            ],
            "description": [
                1
            ],
            "details": [
                5
            ],
            "gifImgKey": [
                1
            ],
            "id": [
                2
            ],
            "imgKey": [
                1
            ],
            "isDeleted": [
                11
            ],
            "isNew": [
                11
            ],
            "isPublished": [
                11
            ],
            "name": [
                1
            ],
            "order": [
                2
            ],
            "originalDesignImgKey": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Boolean": {},
        "Lookups": {
            "key": [
                1
            ],
            "type": [
                1
            ],
            "value": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MissingFileInput": {
            "action": [
                1
            ],
            "id": [
                2
            ],
            "name": [
                1
            ],
            "projectId": [
                2
            ],
            "__typename": [
                1
            ]
        },
        "MultipartUpload": {
            "multipartUploadUrls": [
                1
            ],
            "uploadId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Mutation": {
            "abortMultipartUpload": [
                1,
                {
                    "s3Key": [
                        1,
                        "String!"
                    ],
                    "uploadId": [
                        1,
                        "String!"
                    ]
                }
            ],
            "activateUser": [
                32,
                {
                    "name": [
                        1,
                        "String!"
                    ],
                    "title": [
                        1,
                        "String!"
                    ],
                    "user_id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "addMissingFiles": [
                18,
                {
                    "name": [
                        1,
                        "String!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "addPage": [
                25,
                {
                    "description": [
                        1
                    ],
                    "isPublished": [
                        11
                    ],
                    "iterations": [
                        10,
                        "[IterationInput!]"
                    ],
                    "originalParentId": [
                        2
                    ],
                    "parentId": [
                        2
                    ],
                    "partId": [
                        2,
                        "Int!"
                    ],
                    "statusKey": [
                        1
                    ],
                    "templateKey": [
                        1
                    ],
                    "title": [
                        1,
                        "String!"
                    ]
                }
            ],
            "addProjectOriginalData": [
                19,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "projectPartId": [
                        2,
                        "Int!"
                    ],
                    "subjectId": [
                        2,
                        "Int!"
                    ],
                    "value": [
                        6,
                        "Float!"
                    ],
                    "value2": [
                        6
                    ]
                }
            ],
            "addProjectSubjects": [
                23,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "subjectIds": [
                        2,
                        "[Int!]!"
                    ]
                }
            ],
            "addUploadLink": [
                30,
                {
                    "linkUrl": [
                        1,
                        "String!"
                    ],
                    "projectId": [
                        2
                    ]
                }
            ],
            "assignFileToProject": [
                17,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "sessionId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "assignTagsToSubjects": [
                1,
                {
                    "subjectIds": [
                        2,
                        "[Int!]!"
                    ],
                    "tagIds": [
                        2,
                        "[Int!]!"
                    ]
                }
            ],
            "assignToProject": [
                30,
                {
                    "id": [
                        2,
                        "Int!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "changeUserRoleName": [
                32,
                {
                    "companyId": [
                        2,
                        "Int!"
                    ],
                    "roleName": [
                        1,
                        "String!"
                    ],
                    "userId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "completeMultipartUpload": [
                1,
                {
                    "completedParts": [
                        4,
                        "[CompletePart!]!"
                    ],
                    "s3Key": [
                        1,
                        "String!"
                    ],
                    "uploadId": [
                        1,
                        "String!"
                    ]
                }
            ],
            "createClient": [
                0,
                {
                    "adminEmail": [
                        1,
                        "String!"
                    ],
                    "company": [
                        3,
                        "CompanyInput!"
                    ],
                    "domain_names": [
                        1,
                        "[String!]"
                    ]
                }
            ],
            "createFilesSession": [
                7
            ],
            "createProject": [
                16,
                {
                    "address": [
                        1
                    ],
                    "architect": [
                        1
                    ],
                    "audience": [
                        2
                    ],
                    "bridgeSpan": [
                        6
                    ],
                    "buildArea": [
                        6
                    ],
                    "capacity": [
                        6
                    ],
                    "clientStatus": [
                        1,
                        "String!"
                    ],
                    "companyId": [
                        2
                    ],
                    "consultant": [
                        1
                    ],
                    "height": [
                        6
                    ],
                    "length": [
                        6
                    ],
                    "name": [
                        1,
                        "String!"
                    ],
                    "numberOfFloors": [
                        2
                    ],
                    "numberOfTanks": [
                        2
                    ],
                    "numberOfUnits": [
                        2
                    ],
                    "otherType": [
                        1
                    ],
                    "owner": [
                        1
                    ],
                    "scope": [
                        1,
                        "String!"
                    ],
                    "stadiumArea": [
                        6
                    ],
                    "storageArea": [
                        6
                    ],
                    "type": [
                        1,
                        "String!"
                    ],
                    "typicalFloorArea": [
                        6
                    ],
                    "typicalFloorHeight": [
                        6
                    ],
                    "usage": [
                        1,
                        "[String!]"
                    ],
                    "volumeOfTanks": [
                        6
                    ]
                }
            ],
            "createProjectFile": [
                17,
                {
                    "companyId": [
                        2
                    ],
                    "filename": [
                        1
                    ],
                    "projectId": [
                        2
                    ],
                    "sessionId": [
                        2
                    ]
                }
            ],
            "createSubject": [
                28,
                {
                    "name": [
                        1,
                        "String!"
                    ],
                    "priority": [
                        2,
                        "Int!"
                    ],
                    "tags": [
                        2,
                        "[Int!]"
                    ],
                    "typeKey": [
                        1,
                        "String!"
                    ]
                }
            ],
            "createSubjectTag": [
                26,
                {
                    "name": [
                        1,
                        "String!"
                    ]
                }
            ],
            "createVaEPackage": [
                33,
                {
                    "originalParentId": [
                        2
                    ],
                    "package": [
                        36,
                        "VaePackageInput!"
                    ],
                    "parentId": [
                        2
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "projectPartId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "deleteMissingFiles": [
                18,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "deleteProjectOriginalData": [
                19,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "deleteProjectSubject": [
                23,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "subjectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "deleteUser": [
                32,
                {
                    "userId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "downloadLink": [
                30,
                {
                    "id": [
                        2,
                        "[Int!]!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "forceCompanysDelete": [
                0,
                {
                    "companyIds": [
                        2,
                        "[Int!]!"
                    ]
                }
            ],
            "forceProjectsDelete": [
                16,
                {
                    "projectIds": [
                        2,
                        "[Int!]!"
                    ]
                }
            ],
            "generateAssetsUploadURL": [
                29,
                {
                    "filename": [
                        1,
                        "String!"
                    ]
                }
            ],
            "generateMultiPartUploadURL": [
                14,
                {
                    "partsCount": [
                        2,
                        "Int!"
                    ],
                    "s3Key": [
                        1,
                        "String!"
                    ]
                }
            ],
            "getFilesUrlsByUploadLogIds": [
                27,
                {
                    "uploadLogIds": [
                        2,
                        "[Int!]!"
                    ]
                }
            ],
            "inviteUsersToMyCompany": [
                32,
                {
                    "companyId": [
                        2
                    ],
                    "emails": [
                        1,
                        "[String!]!"
                    ],
                    "message": [
                        1
                    ],
                    "roleName": [
                        1,
                        "String!"
                    ]
                }
            ],
            "logUpload": [
                31,
                {
                    "filesCount": [
                        2,
                        "Int!"
                    ],
                    "isFile": [
                        11,
                        "Boolean!"
                    ],
                    "name": [
                        1,
                        "String!"
                    ],
                    "projectFileIds": [
                        2,
                        "[Int!]!"
                    ],
                    "projectId": [
                        2
                    ],
                    "sessionId": [
                        2
                    ],
                    "totalSize": [
                        6,
                        "Float!"
                    ]
                }
            ],
            "markAsNotNew": [
                1
            ],
            "markFilesAsDownloaded": [
                31,
                {
                    "logIds": [
                        2,
                        "[Int!]!"
                    ]
                }
            ],
            "missingFileReceived": [
                18,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "missingFileSubmitted": [
                18,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "onPackageDownload": [
                1,
                {
                    "all": [
                        11
                    ],
                    "packageId": [
                        2
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "postMissingFiles": [
                18,
                {
                    "project_id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "publishReportPageImmediately": [
                25,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "publishVaePackage": [
                33,
                {
                    "packageId": [
                        2,
                        "Int!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "registerUserToNewCompany": [
                0,
                {
                    "contractor_type_key": [
                        1
                    ],
                    "country": [
                        1,
                        "String!"
                    ],
                    "industry_key": [
                        1,
                        "String!"
                    ],
                    "name": [
                        1,
                        "String!"
                    ],
                    "other_type": [
                        1
                    ],
                    "size_key": [
                        1
                    ]
                }
            ],
            "removePage": [
                25,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "removeVaEPackage": [
                1,
                {
                    "packageId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "saveProjectUnderstandingForm": [
                1,
                {
                    "breakdownImgKey": [
                        1
                    ],
                    "parts": [
                        21,
                        "[ProjectPartInput!]!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "sendUnderstandingDoc": [
                1,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "setProjectManager": [
                1,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "userId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "shareProject": [
                39,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "userIds": [
                        2,
                        "[Int!]!"
                    ]
                }
            ],
            "unshareProject": [
                1,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "userIds": [
                        2,
                        "[Int!]!"
                    ]
                }
            ],
            "updateCompany": [
                0,
                {
                    "address": [
                        1
                    ],
                    "contractor_type_key": [
                        1
                    ],
                    "country": [
                        1
                    ],
                    "currency_key": [
                        1
                    ],
                    "email": [
                        1
                    ],
                    "id": [
                        2,
                        "Int!"
                    ],
                    "industry_key": [
                        1
                    ],
                    "logo_key": [
                        1
                    ],
                    "name": [
                        1
                    ],
                    "other_type": [
                        1
                    ],
                    "phone": [
                        1
                    ],
                    "size_key": [
                        1
                    ]
                }
            ],
            "updateMissingFiles": [
                18,
                {
                    "id": [
                        2,
                        "Int!"
                    ],
                    "name": [
                        1,
                        "String!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "updatePageBulk": [
                1,
                {
                    "id": [
                        2,
                        "Int!"
                    ],
                    "iterations": [
                        10,
                        "[IterationInput!]!"
                    ],
                    "title": [
                        1,
                        "String!"
                    ]
                }
            ],
            "updateProjectData": [
                16,
                {
                    "address": [
                        1
                    ],
                    "architect": [
                        1
                    ],
                    "audience": [
                        2
                    ],
                    "bridgeSpan": [
                        6
                    ],
                    "buildArea": [
                        6
                    ],
                    "capacity": [
                        6
                    ],
                    "clientStatus": [
                        1
                    ],
                    "companyId": [
                        2,
                        "Int!"
                    ],
                    "consultant": [
                        1
                    ],
                    "height": [
                        6
                    ],
                    "length": [
                        6
                    ],
                    "name": [
                        1
                    ],
                    "numberOfFloors": [
                        2
                    ],
                    "numberOfTanks": [
                        2
                    ],
                    "numberOfUnits": [
                        2
                    ],
                    "otherType": [
                        1
                    ],
                    "owner": [
                        1
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "scope": [
                        1
                    ],
                    "stadiumArea": [
                        6
                    ],
                    "storageArea": [
                        6
                    ],
                    "type": [
                        1
                    ],
                    "typicalFloorArea": [
                        6
                    ],
                    "typicalFloorHeight": [
                        6
                    ],
                    "usage": [
                        1,
                        "[String!]"
                    ],
                    "volumeOfTanks": [
                        6
                    ]
                }
            ],
            "updateProjectOriginalData": [
                19,
                {
                    "id": [
                        2,
                        "Int!"
                    ],
                    "value": [
                        6,
                        "Float!"
                    ],
                    "value2": [
                        6
                    ]
                }
            ],
            "updateProjectStatus": [
                16,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "status": [
                        1,
                        "String!"
                    ]
                }
            ],
            "updateProjectSubjects": [
                23,
                {
                    "priority": [
                        2,
                        "Int!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "subjectId": [
                        2,
                        "Int!"
                    ],
                    "unit": [
                        1,
                        "String!"
                    ]
                }
            ],
            "updateProjectUnderstandingDoc": [
                1,
                {
                    "docS3key": [
                        1,
                        "String!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "updateSubject": [
                28,
                {
                    "id": [
                        2,
                        "Int!"
                    ],
                    "name": [
                        1
                    ],
                    "priority": [
                        2
                    ],
                    "tags": [
                        2,
                        "[Int!]"
                    ],
                    "typeKey": [
                        1
                    ]
                }
            ],
            "updateSubjectTag": [
                26,
                {
                    "id": [
                        2,
                        "Int!"
                    ],
                    "name": [
                        1,
                        "String!"
                    ]
                }
            ],
            "updateUser": [
                32,
                {
                    "email": [
                        1
                    ],
                    "img_key": [
                        1
                    ],
                    "language": [
                        1
                    ],
                    "name": [
                        1
                    ],
                    "phone": [
                        1
                    ],
                    "title": [
                        1
                    ],
                    "userId": [
                        2
                    ]
                }
            ],
            "updateVaEDetailedPackage": [
                33,
                {
                    "package": [
                        36,
                        "VaePackageInput!"
                    ],
                    "packageId": [
                        2,
                        "Int!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "Project": {
            "address": [
                1
            ],
            "architect": [
                1
            ],
            "audience": [
                2
            ],
            "breakDownImg": [
                27
            ],
            "bridgeSpan": [
                6
            ],
            "buildArea": [
                6
            ],
            "capacity": [
                6
            ],
            "clientStatus": [
                12
            ],
            "company": [
                0
            ],
            "companyId": [
                2
            ],
            "consultant": [
                1
            ],
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "currencyKey": [
                1
            ],
            "description": [
                1
            ],
            "draftUnderstandingDoc": [
                27
            ],
            "height": [
                6
            ],
            "id": [
                2
            ],
            "imgUrl": [
                1
            ],
            "isDemo": [
                11
            ],
            "length": [
                6
            ],
            "members": [
                32
            ],
            "name": [
                1
            ],
            "numberOfFloors": [
                2
            ],
            "numberOfTanks": [
                2
            ],
            "numberOfUnits": [
                2
            ],
            "otherType": [
                1
            ],
            "owner": [
                1
            ],
            "parts": [
                20
            ],
            "projectManager1Id": [
                2
            ],
            "projectManager2Id": [
                2
            ],
            "publishedUnderstandingDocs": [
                27
            ],
            "scope": [
                1
            ],
            "stadiumArea": [
                6
            ],
            "status": [
                12
            ],
            "storageArea": [
                6
            ],
            "type": [
                12
            ],
            "typicalFloorArea": [
                6
            ],
            "typicalFloorHeight": [
                6
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "usage": [
                12
            ],
            "volumeOfTanks": [
                6
            ],
            "__typename": [
                1
            ]
        },
        "ProjectFile": {
            "company": [
                0
            ],
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "id": [
                2
            ],
            "project": [
                16
            ],
            "s3Key": [
                1
            ],
            "status": [
                1
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "uploadURL": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ProjectMissingFiles": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "id": [
                2
            ],
            "name": [
                1
            ],
            "project": [
                16
            ],
            "status_key": [
                12
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "ProjectOriginalData": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "id": [
                2
            ],
            "priority": [
                23
            ],
            "project": [
                16
            ],
            "projectPart": [
                20
            ],
            "subject": [
                23
            ],
            "tags": [
                26
            ],
            "unit": [
                23
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "value": [
                6
            ],
            "value2": [
                6
            ],
            "__typename": [
                1
            ]
        },
        "ProjectPart": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "detailedReportStatus": [
                12
            ],
            "id": [
                2
            ],
            "isArchived": [
                11
            ],
            "name": [
                1
            ],
            "pages": [
                25
            ],
            "projectId": [
                2
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "ProjectPartInput": {
            "id": [
                2
            ],
            "isArchived": [
                11
            ],
            "isDeleted": [
                11
            ],
            "isNew": [
                11
            ],
            "name": [
                1
            ],
            "order": [
                2
            ],
            "__typename": [
                1
            ]
        },
        "ProjectPublish": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "id": [
                2
            ],
            "pageOrPackageId": [
                2
            ],
            "priority": [
                2
            ],
            "project": [
                2
            ],
            "projectPartId": [
                2
            ],
            "projectPartName": [
                1
            ],
            "subjectId": [
                2
            ],
            "subjectName": [
                1
            ],
            "unitKey": [
                1
            ],
            "unitValue": [
                1
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "value": [
                6
            ],
            "value2": [
                6
            ],
            "__typename": [
                1
            ]
        },
        "ProjectSubject": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "priority": [
                2
            ],
            "project": [
                16
            ],
            "subject": [
                28
            ],
            "tags": [
                26
            ],
            "unit": [
                12
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "Query": {
            "checkMissingVaePackageTotalcost": [
                2,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "company": [
                0,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "currentUser": [
                32
            ],
            "getLookupsOfType": [
                12,
                {
                    "types": [
                        1,
                        "[String!]!"
                    ]
                }
            ],
            "getProject": [
                16,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "getProjectPart": [
                20,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "getProjectSubject": [
                23,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "subjectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "getReportPage": [
                25,
                {
                    "id": [
                        2,
                        "Int!"
                    ],
                    "latestVersion": [
                        11
                    ]
                }
            ],
            "getStaticPage": [
                1,
                {
                    "pageId": [
                        1,
                        "String!"
                    ]
                }
            ],
            "getVaEPackage": [
                33,
                {
                    "packageId": [
                        2,
                        "Int!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "getVaEPackages": [
                33,
                {
                    "latestVersion": [
                        11,
                        "Boolean!"
                    ],
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "isPackageValidForPublish": [
                2,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "isReportPageValidForPublish": [
                1,
                {
                    "id": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "listAllAccessedCompanies": [
                0
            ],
            "listAllCompanies": [
                0
            ],
            "listAllProjectParts": [
                20,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "listAllSubjectTags": [
                26
            ],
            "listAllSubjects": [
                28,
                {
                    "tags": [
                        2,
                        "[Int!]"
                    ]
                }
            ],
            "listAvailibleProjectSubjects": [
                23,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "projectPartId": [
                        2,
                        "Int!"
                    ],
                    "tags": [
                        2,
                        "[Int!]"
                    ]
                }
            ],
            "listCompanyProjects": [
                16,
                {
                    "companyId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "listDemoProjects": [
                16
            ],
            "listMissingFiles": [
                18,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "status": [
                        1,
                        "[String!]"
                    ]
                }
            ],
            "listMissingFilesByStatus": [
                18,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "statusKey": [
                        1,
                        "[String!]!"
                    ]
                }
            ],
            "listProjectPartOriginalData": [
                19,
                {
                    "projectPartId": [
                        2,
                        "Int!"
                    ],
                    "tags": [
                        2,
                        "[Int!]"
                    ]
                }
            ],
            "listProjectPartsPublish": [
                22,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "projectPartId": [
                        2,
                        "Int!"
                    ],
                    "publishType": [
                        1,
                        "String!"
                    ],
                    "reportPageId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "listProjectSubjects": [
                23,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ],
                    "tags": [
                        2,
                        "[Int!]"
                    ]
                }
            ],
            "listProjectUploadLogs": [
                31,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "listUploadLinks": [
                30,
                {
                    "projectId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "user": [
                32,
                {
                    "userId": [
                        2,
                        "Int!"
                    ]
                }
            ],
            "vaesUsers": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "ReportPage": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "description": [
                1
            ],
            "id": [
                2
            ],
            "isPublished": [
                11
            ],
            "iterations": [
                8
            ],
            "originalDesignIteration": [
                8
            ],
            "parentId": [
                2
            ],
            "parentPage": [
                25
            ],
            "part": [
                20
            ],
            "projectPartId": [
                2
            ],
            "status": [
                12
            ],
            "thumbnail": [
                1
            ],
            "title": [
                1
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "versions": [
                25
            ],
            "__typename": [
                1
            ]
        },
        "ReportSubjectTag": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "id": [
                2
            ],
            "name": [
                1
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "S3File": {
            "s3key": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SubjectLookup": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "id": [
                2
            ],
            "name": [
                1
            ],
            "priority": [
                2
            ],
            "tags": [
                26
            ],
            "typeKey": [
                12
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "UploadAsset": {
            "downloadUrl": [
                1
            ],
            "filename": [
                1
            ],
            "s3key": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UploadLink": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "downloadedAt": [
                1
            ],
            "downloadedBy": [
                32
            ],
            "id": [
                2
            ],
            "isDownloaded": [
                11
            ],
            "linkUrl": [
                1
            ],
            "project": [
                16
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "UploadLog": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "filesCount": [
                2
            ],
            "id": [
                2
            ],
            "isDownloaded": [
                11
            ],
            "isFile": [
                11
            ],
            "name": [
                1
            ],
            "totalSize": [
                2
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "__typename": [
                1
            ]
        },
        "User": {
            "company": [
                0
            ],
            "createdAt": [
                1
            ],
            "email": [
                1
            ],
            "imageUrl": [
                1
            ],
            "isActivated": [
                11
            ],
            "isDeleted": [
                11
            ],
            "isNew": [
                11
            ],
            "language": [
                1
            ],
            "name": [
                1
            ],
            "phone": [
                1
            ],
            "roleName": [
                1
            ],
            "title": [
                1
            ],
            "updatedAt": [
                1
            ],
            "userId": [
                2
            ],
            "visitedAt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VaePackage": {
            "advantages": [
                1
            ],
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "description": [
                1
            ],
            "details": [
                34
            ],
            "gifImage": [
                27
            ],
            "id": [
                2
            ],
            "image": [
                27
            ],
            "isDownloaded": [
                11
            ],
            "isPublished": [
                11
            ],
            "isViewed": [
                11
            ],
            "name": [
                1
            ],
            "originalDesignImage": [
                27
            ],
            "originalParentId": [
                2
            ],
            "package": [
                27
            ],
            "parentId": [
                2
            ],
            "project": [
                16
            ],
            "projectPart": [
                20
            ],
            "projectPartId": [
                2
            ],
            "savings": [
                6
            ],
            "summary": [
                1
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "versions": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "VaePackageDetail": {
            "createdAt": [
                1
            ],
            "createdBy": [
                32
            ],
            "id": [
                2
            ],
            "projectPart": [
                20
            ],
            "subject": [
                28
            ],
            "updatedAt": [
                1
            ],
            "updatedBy": [
                32
            ],
            "vaePackageId": [
                2
            ],
            "value": [
                6
            ],
            "value2": [
                6
            ],
            "__typename": [
                1
            ]
        },
        "VaePackageDetailInput": {
            "subjectId": [
                2
            ],
            "value": [
                6
            ],
            "value2": [
                6
            ],
            "__typename": [
                1
            ]
        },
        "VaePackageInput": {
            "advantages": [
                1
            ],
            "description": [
                1
            ],
            "details": [
                35
            ],
            "gifImgKey": [
                1
            ],
            "imgKey": [
                1
            ],
            "name": [
                1
            ],
            "originalDesignImgKey": [
                1
            ],
            "originalParentId": [
                2
            ],
            "packageKey": [
                1
            ],
            "parentId": [
                2
            ],
            "savings": [
                2
            ],
            "summary": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VaePackageUpdateInput": {
            "id": [
                2
            ],
            "package": [
                36
            ],
            "__typename": [
                1
            ]
        },
        "static_page": {
            "createdAt": [
                1
            ],
            "id": [
                2
            ],
            "pageId": [
                1
            ],
            "pageRecord": [
                1
            ],
            "updatedAt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "user_shared_projects": {
            "createdAt": [
                1
            ],
            "id": [
                2
            ],
            "projectId": [
                2
            ],
            "updatedAt": [
                1
            ],
            "userId": [
                2
            ],
            "__typename": [
                1
            ]
        }
    }
}