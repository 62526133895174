import React, { useCallback } from "react"
import { Spin } from "antd"
import EmptyLogsComponent from "../../../components/EmptyLogsComponent/EmptyLogsComponent"
import { useNavigate, useParams } from "react-router"
import { VAES_SUPPORT_EMAIL } from "../../../lib/constants"
import ProjectAPI from "../../../api/project"
import { useProjectPart } from "../../../api/report"
import { useTranslation } from "react-i18next"

type DetailedReportStatus =
    | "client_need_to_select"
    | "detailed_report_in_progress"
    | "detailed_report_sent"

/**
 * Internal component to show different messages based detailed on the report status
 * @NOTE this component is temp until we support downloading detailed reports from the dashboard.
 */
const EmptyStates = () => {
    // router hooks
    const navigate = useNavigate()
    const { projectId, companyId } = useParams() as {
        projectId: string
        companyId: string
    }

    const hasFiles = ProjectAPI.useHasFiles(companyId, projectId)
    const hasReports = ProjectAPI.useHasReport(projectId)

    // TODO: remove translation name spaces
    const { t: translate } = useTranslation("translation")
    const t = (key: string) => translate(key, { ns: "translation" })

    // get report info
    const reportQuery = useProjectPart(Number(projectId))

    // detailed report status
    const detailedReportStatus = reportQuery.data?.detailedReportStatus
        ?.key as DetailedReportStatus
    /**
     * handle contact us click
     */
    const handleContactUsClick = useCallback(() => {
        window.open(`mailto:${VAES_SUPPORT_EMAIL}`, "_blank")
    }, [])

    if (hasFiles === false) {
        return (
            <EmptyLogsComponent
                header={t(
                    "project.emptyComponent.download.noUploadedFiles.header"
                )}
                paragraph={t(
                    "project.emptyComponent.download.noUploadedFiles.header"
                )}
                controls={[
                    {
                        onClick: () => {
                            navigate(
                                `/project/upload/${companyId}/${projectId}`
                            )
                        },
                        buttonText: t(
                            "project.emptyComponent.upload.buttonText"
                        ),
                        buttonType: "primary",
                    },
                ]}
                veGif={"/assets/images/Submit-01.gif"}
            />
        )
    } else if (hasReports === false) {
        return (
            <EmptyLogsComponent
                header={t("project.emptyComponent.download.noReports.header")}
                paragraph={t(
                    "project.emptyComponent.download.noReports.paragraph"
                )}
                controls={[
                    {
                        onClick: () => {
                            navigate(
                                `/project/upload/${companyId}/${projectId}`
                            )
                        },
                        buttonText: t(
                            "project.emptyComponent.reports.buttonText"
                        ),
                        buttonType: "default",
                    },
                ]}
                veGif={"/assets/images/Done-01.gif"}
            />
        )
    } else if (detailedReportStatus === "client_need_to_select") {
        return (
            <EmptyLogsComponent
                rootClassName="mt-0 w-full"
                header={t("project.emptyComponent.download.review.header")}
                paragraph={t(
                    "project.emptyComponent.download.review.paragraph"
                )}
                controls={[
                    {
                        onClick: () => {
                            navigate(
                                `/project/report/${companyId}/${projectId}`
                            )
                        },
                        buttonText: t(
                            "project.emptyComponent.download.review.buttonTextOne"
                        ),
                        buttonType: "default",
                    },

                    {
                        onClick: handleContactUsClick,
                        buttonText: t(
                            "project.emptyComponent.download.review.buttonTextTwo"
                        ),
                        buttonType: "primary",
                    },
                ]}
                veGif={"/assets/images/review-01.gif"}
            />
        )
    } else if (detailedReportStatus === "detailed_report_in_progress") {
        return (
            <EmptyLogsComponent
                rootClassName="mt-0 w-full"
                header={t("project.emptyComponent.download.inProgress.header")}
                paragraph={t(
                    "project.emptyComponent.download.inProgress.paragraph"
                )}
                controls={[
                    {
                        onClick: () => {
                            navigate(
                                `/project/report/${companyId}/${projectId}`
                            )
                        },
                        buttonText: t(
                            "project.emptyComponent.download.inProgress.buttonTextOne"
                        ),
                        buttonType: "default",
                    },

                    {
                        onClick: handleContactUsClick,
                        buttonText: t(
                            "project.emptyComponent.download.inProgress.buttonTextTwo"
                        ),
                        buttonType: "primary",
                    },
                ]}
                veGif={"/assets/images/In-progress-01.gif"}
            />
        )
    } else if (detailedReportStatus === "detailed_report_sent") {
        return (
            <EmptyLogsComponent
                rootClassName="mt-0 w-full"
                header={t("project.outputs.downloadReady")}
                paragraph={t("project.outputs.checkEmail")}
            />
        )
    } else {
        return <Spin spinning size="default" />
    }
}

export default EmptyStates
