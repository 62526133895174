import React, { useContext, useCallback, useMemo } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Alert, Layout as AntLayout, Typography } from "antd"
import Navbar from "../Navbar/Navbar"
import WelcomeBar from "../WelcomeBar/WelcomeBar"
import UploadDrawer from "../UploadDrawer/UploadDrawer"
import { FileUploadContext } from "../../lib/uploadContext"
import { DropzoneProps, useDropzone } from "react-dropzone"
import classnames from "classnames"
import style from "./Layout.module.scss"
import useSrollTop from "../../lib/hooks/useSrollTop"
import Footer from "../Footer/Footer"
import useMixpanelPageOpen from "../../lib/hooks/useMixpanelPageOpen"
import VaesButton from "../VaesButton/VaesButton"
import { useCurrentUser, useIsPreviewing } from "../../api/user"
import { removePreviewToken } from "../../lib/helpers/tokens-helpers"
import {
    dropzoneErrorHandler,
    getRoleLabel,
} from "../../lib/helpers/general-helpers"
import { VaesUserRoles } from "@vaes-dashboard-2/graphql/userRoles"
import confirm from "antd/es/modal/confirm"
import { ExclamationCircleFilled } from "@ant-design/icons"

/**
 * Alerting users that he/her is previewing as client
 */
const PreviewAsClientAlert = () => {
    // react router
    const navigate = useNavigate()

    // api
    const user = useCurrentUser()
    const isPreviewingQuery = useIsPreviewing()

    // if not previewing, don't show alert
    if (!isPreviewingQuery.data) {
        return null
    }

    const showConfirm = () => {
        confirm({
            title: "Do you Want to exit preview?",
            icon: <ExclamationCircleFilled style={{ color: "gold" }} />,
            content: "Exit preview and go back to admin dashboard?",
            okCancel: true,
            onOk() {
                removePreviewToken()
                navigate("/admin")
            },
        })
    }
    return (
        <Alert
            type="warning"
            message={
                <Typography.Text className="text-base font-robotoMedium">
                    Please note: You are currently viewing the dashboard for{" "}
                    {user.data?.currentUser?.company?.name} company, from{" "}
                    {user.data?.currentUser?.name} POV, acting as{" "}
                    {getRoleLabel(
                        user.data?.currentUser?.roleName as VaesUserRoles
                    )}
                    <VaesButton type="link" onClick={showConfirm}>
                        Exit preview
                    </VaesButton>
                </Typography.Text>
            }
        />
    )
}

function Layout() {
    const uploadCX = useContext(FileUploadContext)
    const location = useLocation()

    useSrollTop()

    /**
     * On drop handler, add files to session since we don't know which project they belong to
     */
    const onDrop = useCallback<NonNullable<DropzoneProps["onDrop"]>>(
        (acceptedFiles, _, evt) => {
            uploadCX?.addFileToSession(acceptedFiles)
            // Stop propagation to prevent opening file dialog or invoking other handlers
            evt?.stopPropagation()
        },
        [uploadCX?.addFileToSession]
    )

    const isDragDisabled = useMemo(() => {
        if (
            location.pathname === "/user-profile" ||
            location.pathname === "/company-profile"
        ) {
            return true
        } else {
            return false
        }
    }, [location.pathname])
    /**
     * Initialize dropzone
     */
    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        disabled: isDragDisabled,
        onError: dropzoneErrorHandler,
    })
    /**
     * ADD mixpanel event when url changes
     */
    useMixpanelPageOpen()
    return (
        <AntLayout className="min-h-screen">
            <Navbar />
            <PreviewAsClientAlert />

            {location.pathname == "/" && <WelcomeBar />}
            <AntLayout.Content
                {...getRootProps({
                    // Not opening file dialog on click
                    onClick: (event) => event?.stopPropagation(),
                })}
                className={classnames(style.content, {
                    "bg-blue-100": isDragActive,
                })}
            >
                <Outlet />
            </AntLayout.Content>

            {
                // Show drawer only in case there is a files
                // uploadCX?.state.files.length > 0 && matches && <UploadDrawer />
                uploadCX?.state.files.length && <UploadDrawer />
            }
            <Footer />
        </AntLayout>
    )
}

export function LayoutNavbarOnly() {
    /**
     * ADD mixpanel event when url changes
     */
    useMixpanelPageOpen()

    return (
        <AntLayout className="min-h-screen">
            <Navbar logoutOnly />

            <AntLayout.Content>
                <Outlet />
            </AntLayout.Content>
        </AntLayout>
    )
}

export default Layout
