import React, { useMemo } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ProjectStepItem from "../../../../components/Project/partial/ProjectStepItem/ProjectStepItem"
import { Steps } from "antd"

export const ProjectSteps = () => {
    const { projectId } = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    /** list the url for each step */
    const currentStep = useMemo(() => {
        if (location.pathname.includes(`/admin/project/${projectId}/files`)) {
            return 0
        } else if (
            location.pathname.includes(`/admin/project/${projectId}/report`)
        ) {
            return 1
        } else if (
            location.pathname.includes(
                `/admin/project/${projectId}/detailed-packages`
            )
        ) {
            return 2
        }
    }, [location.pathname, projectId])

    const onChange = (value: number) => {
        if (value == 0) {
            navigate(`/admin/project/${projectId}/files`)
        } else if (value == 1) {
            navigate(`/admin/project/${projectId}/report/understanding`)
        } else if (value == 2) {
            navigate(`/admin/project/${projectId}/detailed-packages`)
        }
    }

    /**call the step item 3 time with diffrent param to form the stem component */
    const renderSteps = useMemo(() => {
        const steps = [
            {
                title: (
                    <ProjectStepItem
                        stepImageSrc="/assets/images/Step 1-black.png"
                        stepImageAlt="step 1"
                        stepNumber="1. "
                        stepHeader="Client files"
                        stepDesc="Download and review client files"
                    />
                ),
                icon: <></>,
            },
            {
                title: (
                    <ProjectStepItem
                        stepImageSrc="/assets/images/Step 2-black.png"
                        stepImageAlt="step 2"
                        stepNumber="2. "
                        stepHeader="Optimization Reports"
                        stepDesc="Create project understanding & project reports"
                    />
                ),
                icon: <></>,
            },
            {
                title: (
                    <ProjectStepItem
                        stepImageSrc="/assets/images/Step 3-black.png"
                        stepImageAlt="step 3"
                        stepNumber="3. "
                        stepHeader="Upload Detailed VaE Design"
                        stepDesc="Upload savings and detailed design package"
                    />
                ),
                icon: <></>,
            },
        ]
        return steps
    }, [])

    return (
        <Steps
            className=" lg:px-13 px-5"
            type="navigation"
            current={currentStep}
            onChange={onChange}
            responsive={false}
            items={renderSteps}
            rootClassName="tour-project-step"
        />
    )
}
